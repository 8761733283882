import {baseURL} from "@/api/erpApi";

export default {
    mtype: "GET",
    datatype: "json",
    treeGrid: true,
    treeGridModel: 'adjacency',
    ExpandColumn : 'code',
    height: "auto",
    autowidth: true,
    rowList: [10, 20, 50, 100],
    rowNum: 10,
    page: 1,
    loadtext: '<p>Cargando...</p>',
    hoverrows: false,
    refresh: true,
    multiselect: true,
    url: baseURL + '/accounting_accounts',
    treeIcons: {
        plus: "fa fa-chevron-up",
        minus:"fa fa-chevron-down",
        leaf: "fa fa-chevron-down"
    },
    treeReader : {
        level_field: "level",
        parent_id_field: "parentId",
        leaf_field: "isLeaf"
    },

    colNames: [
        "No. cuenta contable",
        "Nombre",
        "Descripción",
        "Estado",
        '',
        '',
    ],
    colModel: [
        {name: "code", width: 55},
        {name: "name", width: 55},
        {name: "description", width: 55},
        {name: "statusLabel", width: 40, align: 'center'},
        {name: "options", width: 55, align: 'center'},
        {name: "hiddenOptions", hidden: true}
    ],
    sortname: 'code',
    sortorder: "asc",
    viewrecords: true,
    gridview: true,
};
