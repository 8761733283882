<template>
    <div class="xcontainer">
        <div>
            <div class="align-items-center d-flex div-title-card justify-content-between row">
                <div class="align-items-baseline d-sm-flex flex-md-row flex-sm-column">
                    <h5 class="xtitle-buscar">Lista de cuentas contables </h5>
                    <p class="ml-md-3 ml-sm-0 pt-md-0 pt-sm-1 xsubtitle-buscar">(Tabla principal)</p>
                </div>
                <a href="#" @click.prevent="togglePanel">
                    <i class="fa"
                       :class="{'fa-chevron-down': !showPanel, 'fa-chevron-up': showPanel}"></i></a>
            </div>

            <div :class="{collapse: !showPanel}">
                <div class="table-responsive">
                    <table :id="'list' + tableName">
                        <tr>
                            <td></td>
                        </tr>
                    </table>
                    <div :id="'pager' + tableName"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted} from "vue";
import jqgrid from "@/modules/accountingAccounts/config/jqgrid";
import useAuth from "@/modules/auth/composables/useAuth";
import usePanel from "@/composables/usePanel";
import useMainTable from "@/composables/useMainTable";
import toastr from "toastr";
import erpApi from "@/api/erpApi";

export default {
    props: ['tableName'],
    setup(props) {
        const {companyId} = useAuth()
        const {showPanel, togglePanel} = usePanel()

        const onGeneratePlan = (companyId, selector = '.copyPlan, #copyPlan') => {
            const body = window.$('body');
            const list = body.find("#list" + props.tableName);
            body.off('click', selector).on('click', selector, function (e) {
                e.preventDefault();

                erpApi.post('/accounting_accounts/generate_plan', {companyId}).then(() => {
                    toastr.success('Su solicitud se ha procesado correctamente.')
                    list.trigger('reloadGrid');
                }).catch((error) => {
                    console.error(error);
                    toastr.error(error.response.data.message);
                });
            });
        }

        onMounted(() => {
            const body = window.$('body');
            const list = body.find("#list" + props.tableName);
            const pager = body.find("#pager" + props.tableName);

            const {onViewOptions, onShow, onChangeStatus, onStateButton} = useMainTable(props.tableName)

            onViewOptions('accounting_accounts/options_list')
            onShow('accounting_accounts.edit')
            onChangeStatus('accounting_accounts/status_list')
            onStateButton('accounting_accounts/status_list')
            onGeneratePlan(companyId.value)

            list.jqGrid({
                ...jqgrid,
                pager,
                postData: {
                    filters: [{field: 'companyId', value: companyId.value}]
                }
            });
        })

        return {
            showPanel,
            togglePanel,
            onGeneratePlan
        }
    }
}
</script>
