
import MainTable from "@/modules/accountingAccounts/components/MainTable.vue";
import SearchForm from "@/modules/accountingAccounts/components/SearchForm.vue";
export default {
    components: { SearchForm, MainTable },
    setup() {
        const tableName = 'accounting_accounts'
        return {
            tableName
        }
    }
}
